const dk = {
  title: "",
  timeline: "Tidslinje",
  welcome1: "Verificering",
  welcome2: "Indtast den personlige kode, som vi har sendt til dig via SMS",
  statuseventcontainerheader: "Registrering",
  statuscontainerevent0: "",
  statuscontainerevent1: "Din sag er registreret",
  statuscontainerevent2: "Anmodning om hjælp modtaget",
  statuscontainerevent3: "Servicebilen har modtaget opgaven",
  statuscontainerevent4: "Servicebilen er på vej",
  statuscontainerevent5: "Servicebilen er ankommet",
  statuscontainerevent6: "Klar på stedet",
  statuscontainerevent7: "Starter transport til leveringssted",
  statuscontainerevent8: "Klar på leveringsstedet",
  statuscontainerevent9: "Bjærgningen gennemført",
  statuscontainerevent10: "Aflyst",
  statuscontainerevent11: "Aflyst",
  statuscontainer1: "Sag",
  EventsTitle: "",
  error1: "Forkert pinkode, prøv venligst igen",
  error2:
    "Noget gik galt. Vi arbejder på at prøve at løse problemet. Prøv igen senere.",
  ETA: "",
  ETAtext: "",
  ETAZero: '',
  ETAtextCountdownCompleted: "Ankommer straks",
  ContactText: "",
  ContactPageHeader1: "",
  ContactPageHeader2: "",
  ContactPageNumber1: "",
  ContactPageNumber2: "",
  ChatBotButton: "",
  EventListHeaderPolestar:"Min sag",
  PolestarContactHeader:"Har du brug for hjælp?",
  ContactTextPolestar: 'Ring til assistance',
  PolestarContactNumber: 'tel:+4589881103'
};
export default dk;