import { makeAutoObservable, runInAction } from "mobx";
import { makePersistable } from "mobx-persist-store";
import { RootStore } from ".";
import { Case, CaseActionEvent, ServiceContact } from "../api/dataModel";
import { getCountry } from "../components/CountryDetector";
import { ProductConfigWithCountries } from "../Configs/Models/ProductConfig";
import CountryConfig from "../Configs/Models/CountryConfig";
import ProductConfigs from "../Configs/ProductConfigs";

export default class CasesStore {
    private rootStore: RootStore;

    requestUrl?: string = null;

    caseId?: number = null;
    conceptId?: number = null;

    case?: Case = null;
    latestEvent?: CaseActionEvent = null;
    serviceContactInfo?: ServiceContact = null;
    events?: CaseActionEvent[] = null;
    isCaseLoaded?: boolean = null;
    errorCause: "ServerError" | "PinError" | null = null;

    productConfig?: ProductConfigWithCountries = null;
    countryConfig?: CountryConfig = null;

    constructor(rootStore: RootStore) {
        this.rootStore = rootStore;

        // without autobind can only use arrow function
        makeAutoObservable(this, {}, { autoBind: true });

        makePersistable<CasesStore, keyof CasesStore>(this, {
            name: "RsaDataUi-CasesStore",
            storage: window.localStorage,
            expireIn: 1000 * 3600 * 2, // 2h in milliseconds
            properties: ["caseId", "conceptId"]
        });
    }

    get conceptName(): "Volvia" | "Polestar" | "Other" | "Hedin" {
        if (this.conceptId === 1034) {
            return "Polestar";
        }
        if (this.conceptId === 1003 || this.conceptId === 1042) {
            return "Volvia";
        }
        if(this.conceptId === 1046 || this.conceptId === 2046) {
            return "Hedin";
        }
        return "Other";
    }

    setCaseAndConceptIds(caseId: number, conceptId: number) {
        this.caseId = caseId;
        this.conceptId = conceptId;

        this.countryConfig = getCountry();
        this.productConfig = ProductConfigs.getById(this.conceptId);
    }

    async loadCase() {
        const result = await this.rootStore.apiClient.getCase(this.caseId, this.requestUrl)

        // update of state values in async functions should be wrapped in runInAction(()=>{...})
        runInAction(() => {
            const newCase = result.isError ? null : result.cases;
            if (newCase) {
                this.conceptId = newCase.productId;
            }
            this.setCase(newCase);
            this.rootStore.pinStore.setPinValidity(!result.isError);
            this.errorCause = result.errorType ?? null;
        });
    }

    setCase(newCase: Case | null) {
        this.case = newCase;
        if (newCase == null) {
            this.latestEvent = null;
            this.isCaseLoaded = false;
            this.events = null;
            this.serviceContactInfo = null;
        } else {
            this.latestEvent = newCase.actions[0].events.slice(-1)[0];
            this.isCaseLoaded = true;
            this.events = newCase.actions.flatMap(x => x.events);
            this.serviceContactInfo = newCase.actions[0].serviceContacts[0];
        }
    }

    resetError() {
        this.errorCause = null;
    }
}