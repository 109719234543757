import React from "react";
import InfoContainerHedin from "../InfoContainer/InfoContainerHedin";
import EventsComponentHedin from "../EventsComponentHedin";
// import MapContainer from "../MapContainer/MapContainer";
import { observer } from "mobx-react-lite";

const StatusContainerHedin = () => {
  return (
    <div>
      <div id="StatusContainerHedinMain">
        <InfoContainerHedin/>
      </div>
      {/* <MapContainer /> */}
      <EventsComponentHedin />
    </div>
  );
};

export default observer(StatusContainerHedin);
