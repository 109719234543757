const se = {
  title: "Kontakt",
  timeline: "Tidslinje",
  welcome1: "Verifiering",
  welcome2: "Skriv in den personliga koden som vi skickade till dig via SMS",
  statuseventcontainerheader: "Registrering",
  statuscontainerevent0: "Unknown",
  statuscontainerevent1: "Ditt ärende är registrerat",
  statuscontainerevent2: "Begäran om assistans mottagen",
  statuscontainerevent3: "Bärgare har tagit emot uppdraget",
  statuscontainerevent4: "Bärgaren på väg",
  statuscontainerevent5: "Bärgaren framme",
  statuscontainerevent6: "Klar på platsen",
  statuscontainerevent7: "Startar transport till avlämningsplats",
  statuscontainerevent8: "Klar på avlämningsplatsen",
  statuscontainerevent9: "Bärgningen avslutad",
  statuscontainerevent10: "Cancelled",
  statuscontainerevent11: "Interrupted",
  statuscontainer1: "Bärgning",
  EventsTitle: "Händelser",
  error1: "Fel pinkod, försök igen.",
  error2: "Något gick fel. Vi arbetar med att försöka lösa problemet. Vänligen försök igen senare.",
  ETA: "",
  ETAtext: "{{eta}} min",
  ETAtextH: "{{etah}} t {{eta}} min",
  ETAZero: 'på plats',
  ETAtextCountdownCompleted: "Anländer snart",
  ContactText: "Kontakta bärgare",
  ContactText2: "Kontakta Viking",
  ContactText3: "Kontakta Kia Assistans",
  ContactPageHeader1: "Kontakta alarmcentral",
  ContactPageHeader2: "Kontakta bärgare",
  ContactPageNumber1: "020-100100",
  ContactPageNumber2: "",
  ContactPageNumber3: "0770-117 000",
  ChatBotButton: "Anmäl din skada här",
  ChatBotHeader: "Här kan du anmäla din skada medan du väntar på bärgningsbilen ",
  EventListHeaderPolestar:"Min bärgning",
  PolestarContactHeader:"Behöver du hjälp?",
  ContactTextPolestar: 'Ring Assistance',
  PolestarContactNumber: 'tel:+4620889228',
  CurrentStatusHedin: "Aktuell status",
  EstimatedArrivalHedin: "Beräknad ankomst",
};
export default se;
