import { observer } from "mobx-react-lite";
import React from "react";
import { useTranslation } from "react-i18next";
import { useStore } from "../../../Store";
import i18n from "../../Translations";
import CallButtonModel from "../../CallButtonModel";
import ETAcomponent from "./ETAcomponent";

const InfoContainerHedin = () => {
  const { casesStore } = useStore();
  const { t } = useTranslation();
  const locale = i18n.language;
  const sProductId = casesStore.conceptId;

  let sLatestEvent = casesStore.latestEvent;
  setInterval(() => {
    const sLatestEventT = casesStore.latestEvent;
    let sProductIdT = casesStore.conceptId;

    if (sProductIdT !== sProductId) {
      sProductIdT = sProductId;
    }
    
    if (sLatestEventT !== sLatestEvent) {
      sLatestEvent = sLatestEventT;
    }
  }, 10000);

  if (sLatestEvent == null) {
    return (
      <div id="info-container-hedin"></div>
    );
  }

  return (
    <div id="info-container-hedin"> 
     <article className="container-hedin">
 
       <div className="container bk-color-product">
        
        <div className="padding--10">
          <div className={`logo-viking-hedin margin--top-10`}></div>
        <div>
         <p>{t("EstimatedArrivalHedin")}</p>
         <div className="time-container">
          <p>
            {t("ETA")}
            <ETAcomponent />
          </p>
         </div>  
        </div>
          <hr/>
          <p>{t("CurrentStatusHedin")}:</p>
          <p className="bold-text"> {t(`statuscontainerevent${sLatestEvent.eventType}`)}</p>
          </div>
       </div>
             
       <div className="container margin--top-30 margin--bottom-30">
          <CallButtonModel product={sProductId} locale={locale}/>
       </div>
     </article>
  </div>
    
  );
};

export default observer(InfoContainerHedin);
