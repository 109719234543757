import React, { useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router";
import HeaderVolvia from "../../components/Header/HeaderVolvia";
import HeaderPolestar from "../../components/Header/HeaderPolestar";
import Header from "../../components/Header/Header";
import ErrorWarning from "./errorWarning";
import { useStore } from "../../Store";
import { observer } from "mobx-react-lite";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

const PinRoute: React.FC = () => {
    const params = useParams<{ caseId, conceptId }>();;
    const [caseId, conceptId] = [parseInt(params.caseId), parseInt(params.conceptId)];

    const navigate = useNavigate();
    const { casesStore, pinStore, loadingStore } = useStore();
    const { t } = useTranslation();

    const [errorType, setErrorType] = useState("");
    const inputRefs = useMemo(() => pinStore.digits.map(_ => React.createRef<HTMLInputElement>()), [pinStore.digits]);
    var requestUrl = null;

    // Set requestUrl on initialization
    useEffect(() => {
        var forwardedHost = window.location.hostname;
        //if development sadfadf
        if (forwardedHost.includes("localhost")) {
            forwardedHost = process.env.REACT_APP_API_URL;
        } else {
            const requestUrl = forwardedHost ? `https://${forwardedHost}/` : null;
        }
        casesStore.requestUrl = requestUrl;
        forwardedHost = forwardedHost ? `https://${forwardedHost}/` : null;
    casesStore.requestUrl = forwardedHost;
    }, [casesStore, requestUrl]);

    // Set caseId and conceptId on initialization
    useEffect(() => {
        casesStore.setCaseAndConceptIds(caseId, conceptId);
    }, [casesStore, caseId, conceptId]);

    // When finished entering pin, validate it
    useEffect(() => {
        if (pinStore.isPinComplete) {
            casesStore.loadCase();
        }
    }, [pinStore.isPinComplete, casesStore]);

    // Update textbox style
    useEffect(() => {
        if (casesStore.errorCause === "PinError" && pinStore.isPinValid !== true) {
            setErrorType("pin");
            pinStore.clearPinButKeepValidityState();
        }
        else if (casesStore.errorCause === "ServerError") {
            setErrorType("server");
        }
        else {
            setErrorType("");
        }
    }, [pinStore, pinStore.isPinValid, casesStore.errorCause]);

    // Redirect if pin was correct
    useEffect(() => {
        if (pinStore.isPinValid !== true) {
            return;
        }
    
        // if there is no events for action 
        if (casesStore.conceptId <= 1999) {
            if(casesStore.latestEvent == null) {
                pinStore.clearPinButKeepValidityState();
                casesStore.errorCause = "ServerError";
                return;
            }
        }
    
        let redirectTo = `/${caseId}/assistance/home`;
        if (casesStore.conceptName === "Polestar") {
            redirectTo = `/${caseId}/polestar/home`;
            
        } else if (casesStore.conceptName === "Volvia") {
            redirectTo = `/${caseId}/volvia/home`;

        } else if (casesStore.conceptId === 1046) {
            redirectTo = `/${caseId}/hedin/home`;

        } else if (casesStore.conceptId > 1999) {
            redirectTo = `/${caseId}/nct/home`;
        }
    
        window.setTimeout(() => navigate(redirectTo, {state: 500, replace: true}));
    }, [pinStore.isPinValid, casesStore.conceptName, navigate, caseId]);

    // Focus on textfox when currentDigitIdx has changed
    useEffect(() => {
        inputRefs[pinStore.currentDigitIdx].current.focus();
    }, [pinStore.currentDigitIdx, inputRefs]);

    const valueSelector = ({ volvia, polestar, other }) => {
        if (casesStore.conceptName === "Polestar") {
            return polestar;
        } else if (casesStore.conceptName === "Volvia") {
            return volvia
        }
        return other;
    };

    return (
        <React.Fragment>
            {valueSelector({
                volvia: (<HeaderVolvia />),
                polestar: (<HeaderPolestar />),
                other: (<Header productId={conceptId} />)
            })}

            <div className="pin-texts">
                <div className={valueSelector({
                    volvia: "pin-text-first-volvia",
                    polestar: "pin-text-first-polestar",
                    other: `pin-text-first product-font-${conceptId}`,
                })}>
                    {t("welcome1")}
                </div>
                <div className={valueSelector({
                    volvia: "pin-text-second-volvia",
                    polestar: "pin-text-second-polestar",
                    other: `pin-text-second product-font-${conceptId}`
                })}>
                    {t("welcome2")}
                </div>
            </div>

            <div
                className={valueSelector({
                    volvia: "pin-inputs-polestar",
                    polestar: "pin-inputs-polestar",
                    other: "pin-inputs"
                })}>
                {[...Array(4)].map((_, i) =>
                    <input
                        key={`number-input-${i}`}
                        ref={inputRefs[i]}
                        className={valueSelector({
                            volvia: `pin-input-field-polestar ${errorType}`,
                            polestar: `pin-input-field-polestar ${errorType}`,
                            other: `pin-input-field ${errorType}`
                        })}
                        type="password"
                        pattern="[0-9]*"
                        autoComplete="one-time-code"
                        maxLength={1}
                        value={pinStore.digits[i] ?? ""}
                        onChange={() => { /* onChange should be present */ }}
                        onKeyUp={(e: React.KeyboardEvent<HTMLInputElement>) => {
                            let digit: number | null | "NotNumber" = "NotNumber";

                            if (e.key === "Backspace" || e.key === "Delete") {
                                digit = null;
                            }
                            else if (e.key.match(/[0-9]/)) {
                                digit = parseInt(e.key);
                            }
                            if (digit === "NotNumber") {
                                return;
                            }

                            pinStore.enterOrRemoveDigit(i, digit);
                            casesStore.resetError();
                        }}
                        onFocus={(e: React.FocusEvent<HTMLInputElement>) => {
                            e.currentTarget.select();
                        }}
                        inputMode="numeric"
                        autoFocus={i === 0}
                        data-index={i}
                    />
                )}
            </div>
            <div>
                {loadingStore.isLoading &&
                    <div className={`loading-bar p-${casesStore.conceptId}`}></div>}
                <ErrorWarning />
            </div>
        </React.Fragment>
    );
}

export default observer(PinRoute);