import React from "react";
import 'moment-timezone';
import { useStore } from "../../Store";
import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";
import TimeDisplay from "./TimeDisplay";

const EventsComponentHedin = () => {
  const { casesStore } = useStore();
  const { t } = useTranslation();

   const caseEvents = [];
   const caseEvents2 = [];

   if (casesStore.events != null) {
     casesStore.events.forEach((item) => {
       if (item.eventType === 1) {
         caseEvents.push(
         <li key={"eventContainer" + item.eventType}>
          <div className="row">
            <div className="circle"/>   
            <p className="status-text">{t(`statuscontainerevent${item.eventType}`)}</p>
            </div>
            <div className="row line-container margin--top-10">
              <div className="line"/>
              <div className="margin--left-20">
                <p className="grey-text">
                  <TimeDisplay dateTime={item.eventDateTime} />
                </p>
              </div>
            </div>
          </li>
         )
       } else {
         caseEvents2.push(
           <li key={"eventContainer" + item.eventType} className="active">
             <div className="row">
              <div className="circle"/>   
                <p className="status-text">{t(`statuscontainerevent${item.eventType}`)}</p>
              </div>
              <div className="row line-container margin--top-10">
                <div className="line"/>
                <div className="margin--left-20">
                  <p className="grey-text"> 
                    <TimeDisplay dateTime={item.eventDateTime} />
                  </p>
                </div>
             </div>
           </li>
         )
       }
     });

     return (
       <div id="status-container-hedin">
         {(() => {
           return (
             <div className="events-container-hedin margin--top-30">
              <div className="timeline-heding">
                <p>{t(`timeline`)}</p>
              </div>
              <div className="events-handler">
                <ul >
                  {caseEvents2.reverse()}
                </ul>
                <ul>
                  {caseEvents.reverse()}
                </ul>
              </div>
             </div>
           )
         })()}
       </div>
     );
   }

  return <div className="loading-bar"></div>;
}

export default observer(EventsComponentHedin);