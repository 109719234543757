import CountryConfigs from "./CountryConfigs";
import MapMarkerConfig from "./Models/MapMarkerConfig";
import { CountryEnum } from "./Models/CountryEnum";
import { ProductConfig, ProductConfigWithCountries } from "./Models/ProductConfig";
import { ProductConfigBuilder } from "./Builders/ProductConfigBuilder";

const DefaultMarker = (urlFactory: () => string): MapMarkerConfig => ({
    urlFactory,
    iconSize: [64, 64],
    iconAnchor: [32, 32]
});

const DefaultConfig = (name: string, ids: number[] | number): ProductConfigBuilder =>
    new ProductConfigBuilder(name, ids)
        .withContactPhone("010191840")
        .andSvgLogoComponentFactory(null)
        //.andMapMarkerForCar(DefaultMarker(() => require("../components/Body/MapContainer/Images/map_marker_car.png")))
        //.andMapMarkerForService(DefaultMarker(() => require("../components/Body/MapContainer/Images/map_marker_service.png")))
        //.andMapMarkerForTowTruck(DefaultMarker(() => require("../components/Body/MapContainer/Images/map_marker_tower.png")))
        .andMapMarkerForCar(DefaultMarker(() => { throw "MAP DISABLED"; }))
        .andMapMarkerForService(DefaultMarker(() => { throw "MAP DISABLED"; }))
        .andMapMarkerForTowTruck(DefaultMarker(() => { throw "MAP DISABLED"; }))
        .byDefault();

export default class ProductConfigs {

    static If1001 = DefaultConfig("If Assistance", [1001, 2001])
        .withContactPhone("4721492400")
        .forNorway()
        .build();

    static Volvia = DefaultConfig("Volvia", [1003, 2003])
        .withSvgLogoComponentFactory(() => require("../components/Resources/Img/Logos/Volvia_1003_2003.svg"))
        //.andMapMarkerForCar(DefaultMarker(() => require("../components/Body/MapContainer/Images/map_marker_car_volvo.png")))
        .byDefault()
        .build();

    static Kia = DefaultConfig("KIA", [1004, 2004])
        .withSvgLogoComponentFactory(() => require("../components/Resources/Img/Logos/KIA_1004_2004.svg"))
        .andContactPhone("0770-117-000")
        .byDefault()
        .withContactPhone("08-587444759")
        .forSweden()
        .build();

    static Subaru = DefaultConfig("Subaru", [1005, 1030, 2005, 2030])
        .withSvgLogoComponentFactory(() => require("../components/Resources/Img/Logos/Subaru_1005_1030_2005_2030.svg"))
        .byDefault()
        .withContactPhone("08-58744715")
        .forSweden()
        .build();

    static Audi = DefaultConfig("Audi", [1006, 2006])
        .withSvgLogoComponentFactory(() => require("../components/Resources/Img/Logos/Audi_1006_2006.svg"))
        .byDefault()
        .withContactPhone("08-58720279")
        .forSweden()
        .build();

    static Volkswagen1007 = DefaultConfig("Volkswagen", [1007, 2007])
        .withSvgLogoComponentFactory(() => require("../components/Resources/Img/Logos/Volkswagen_1007_2007.svg"))
        .byDefault()
        .withContactPhone("08-58720281")
        .forSweden()
        .build();

    static Skoda = DefaultConfig("Skoda", [1008, 2008])
        .withSvgLogoComponentFactory(() => require("../components/Resources/Img/Logos/Skoda_1008_2008.svg"))
        .byDefault()
        .withContactPhone("08-58720280")
        .forSweden()
        .build();

    static Seat = DefaultConfig("Seat", [1009, 2009])
        .withSvgLogoComponentFactory(() => require("../components/Resources/Img/Logos/Seat_1009_2009.svg"))
        .byDefault()
        .withContactPhone("08-58720285")
        .forSweden()
        .build();

    static Porche = DefaultConfig("Porche", [1010, 2010])
        .withSvgLogoComponentFactory(() => require("../components/Resources/Img/Logos/Porche_1010_2010.svg"))
        .byDefault()
        .withContactPhone("08-58720293")
        .forSweden()
        .build();

    static Volkswagen1011 = DefaultConfig("Volkswagen", [1011, 2011])
        .withSvgLogoComponentFactory(() => require("../components/Resources/Img/Logos/Volkswagen_1011_2011.svg"))
        .byDefault()
        .withContactPhone("08-58720282")
        .forSweden()
        .build();

    static Bmw = DefaultConfig("BMW", [1012, 2012])
        .withSvgLogoComponentFactory(() => require("../components/Resources/Img/Logos/BMW_1012_2012.svg"))
        .byDefault()
        .withContactPhone("08-58744734")
        .forSweden()
        .build();

    static Ford = DefaultConfig("Ford", [1013, 2013])
        .withSvgLogoComponentFactory(() => require("../components/Resources/Img/Logos/Ford_1013_2013.svg"))
        .byDefault()
        .withContactPhone("08-58720297")
        .forSweden()
        .build();

    static Mercedes = DefaultConfig("Mercedes-Benz", [1015, 2015])
        // TODO: why is it missing?
        .withContactPhone("")
        .forSweden()
        .build();

    static Mini = DefaultConfig("MINI", [1016, 2016])
        .withContactPhone("08-58744735")
        .forSweden()
        .build();

    static Nissan = DefaultConfig("Nissan", [1017, 2017])
        .withSvgLogoComponentFactory(() => require("../components/Resources/Img/Logos/Nissan_1017_2017.svg"))
        .byDefault()
        .withContactPhone("08-58744717")
        .forSweden()
        .build();

    static Citroen = DefaultConfig("Citroën", [1020, 2020])
        .withContactPhone("08-58744758")
        .forSweden()
        .build();

    static Mazda1021 = DefaultConfig("Mazda", [1021, 2021])
        .withContactPhone("08-58744758")
        .forSweden()
        .build();

    static Peugeot = DefaultConfig("Peugeot", [1022, 2022])
        .withContactPhone("08-58744758")
        .forSweden()
        .build();

    static RullaVidare = DefaultConfig("Rulla Vidare", [1023, 2023])
        .withContactPhone("08-58744758")
        .forSweden()
        .build();

    static If1027 = DefaultConfig("If Assistans", [1027, 2027])
        .build();

    static KiaAssistans = DefaultConfig("Kia Assistans", [1028, 2028])
        .withSvgLogoComponentFactory(() => require("../components/Resources/Img/Logos/Kia_Assistans_1028_2028.svg"))
        .byDefault()
        .withContactPhone("08-58744758")
        .forSweden()
        .build();

    // 1030 - Subaru, see 1005

    static Chevrolet = DefaultConfig("Chevrolet", [1033, 2033])
        // TODO: why is it missing?
        .withContactPhone("")
        .forSweden()
        .build();

    static Polestar = DefaultConfig("Polestar", [1034, 2034])
        .withContactPhone("+4620889228")
        .forSweden()
        .build();

    static LandRover = DefaultConfig("Land Rover", [1036, 2036])
        .withContactPhone("08-58720295")
        .forSweden()
        .build();

    static Jaguar = DefaultConfig("Jaguar", [1037, 2037])
        .withSvgLogoComponentFactory(() => require("../components/Resources/Img/Logos/Jaguar_1037_2037.svg"))
        .byDefault()
        .withContactPhone("08-58720294")
        .forSweden()
        .build();

    static Mazda1040 = DefaultConfig("Mazda", [1040, 2040])
        .withSvgLogoComponentFactory(() => require("../components/Resources/Img/Logos/Mazda_1040_2040.svg"))
        .byDefault()
        .withContactPhone("08-58720286")
        .forSweden()
        .build();

    static SsangYong = DefaultConfig("SsangYong", [1041, 2041])
        .withSvgLogoComponentFactory(() => require("../components/Resources/Img/Logos/SsangYong_1041_2041.svg"))
        .byDefault()
        .build();

    static VolviaCommercial = DefaultConfig("Volvia Commercial", [1042, 2042])
        .build();

    static Cupra = DefaultConfig("Cupra forsakring", [1043, 2043])
        .withSvgLogoComponentFactory(() => require("../components/Resources/Img/Logos/Cupra_forsaking_1043_2043.svg"))
        .byDefault()
        .withContactPhone("08-58744882")
        .forSweden()
        .build();

    static Hongqui = DefaultConfig("Hongqui", [1044, 2044])
        .build();

    static Mg = DefaultConfig("MG", [1045, 2045])
        .withSvgLogoComponentFactory(() => require("../components/Resources/Img/Logos/MG_1045_2045.svg"))
        .byDefault()
        .build();

    static Hedin = DefaultConfig("Hedin", [1046, 2046])
        .withContactPhone("08-58744844")
        .forSweden()
        .build();


    static getAll(): ProductConfigWithCountries[] {
        return Object.values(ProductConfigs).filter((value) => { return value instanceof ProductConfigWithCountries });
    }

    static getById(productId: number): ProductConfigWithCountries {
        return ProductConfigs.getAll().find(p => p.productIds.includes(productId));
    }

    static getByIdAndCountry(productId: number, country: CountryEnum | string): ProductConfig | null {
        if (typeof country === "string") {
            country = CountryConfigs.getCountryByCode(country).enumValue;
        }
        var productConfig = ProductConfigs.getById(productId);
        if(!productConfig) {
            return null;
        }

        var result = productConfig.configsPerCountry.get(country as CountryEnum);
        return result ?? productConfig;
    }
}
