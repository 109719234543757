const fi = {
  title: "Soita",
  timeline: "Aikajana",
  welcome1: "Tunnistautuminen",
  welcome2: "Kirjoita tekstivistissä lähettämämme henkilökohtainen koodi.",
  statuseventcontainerheader: "Rekisteröinti",
  statuscontainerevent0: "Tunnistamaton",
  statuscontainerevent1: "Tapauksesi on rekisteröity",
  statuscontainerevent2: "Palvelupyyntö vastaanotettu",
  statuscontainerevent3: "Hinausauto on vastaanottanut toimeksiannon",
  statuscontainerevent4: "Hinausauto on matkalla",
  statuscontainerevent5: "Hinausauto on perillä",
  statuscontainerevent6: "Hinausauto on paikalla ja valmiina hinaukseen",
  statuscontainerevent7: "Hinaus on aloitettu",
  statuscontainerevent8: "Hinausauto on kohteessa",
  statuscontainerevent9: "Hinaus on valmis",
  statuscontainerevent10: "Peruutettu",
  statuscontainerevent11: "Keskeytetty",
  statuscontainer1: "Hinaukseni",
  EventsTitle: "Tapahtumat",
  error1: "Väärä PIN-koodi, yritä uudelleen.",
  error2:
    "Jokin meni pieleen. Korjaamme ongelmaa. Yritä myöhemmin uudelleen.",
  ETA: "",
  ETAtext: "{{eta}} min",
  ETAtextH: "{{etah}} t {{eta}} min",
  ETAZero: 'paikalla',
  ETAtextCountdownCompleted: "Saapuu pian",
  ContactText: "Soita hinauspalveluun",
  ContactPageHeader1: "Ota yhteys palvelukeskukseen",
  ContactPageHeader2: "Ota yhteys  hinausautoon",
  ContactPageNumber1: "",
  ContactPageNumber2: "",
  ChatBotButton: "",
  EventListHeaderPolestar:"Hinaukseni",
  PolestarContactHeader:"Tarvitsetko apua?",
  ContactTextPolestar: 'Soita apua.',
  PolestarContactNumber: 'tel:+358248092417',
  CurrentStatusHedin: "Nykyinen tila",
  EstimatedArrivalHedin: "Arvioitu saapuminen",
};
export default fi;